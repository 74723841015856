import * as types from '../types'

import { API } from '@/lib/api'
import { fb } from '@/lib/fb'
import { flatten, cleanObject } from '@/lib/utils'
import { FeedbackType, FeedbackPlatform, FeedbackStatus } from '@/lib/enums'
import Vue from 'vue'

const state = () => ({
	feedbacks: [],
	feedback: {},

})

const actions = {
	async list({ commit }, params) {
		this.dispatch('misc/setLoading', true)
		let feedbacks
		try {
			let { docs, prevParams, nextParams, } = await API.get(`/feedbacks`, params)
			commit(types.SET_FEEDBACKS, docs)
			feedbacks = docs
			this.dispatch('misc/setLoading', false)
			this.dispatch('misc/setNext', nextParams)
			this.dispatch('misc/setPrev', prevParams)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			this.dispatch('misc/setLoading', false)
			throw new Error(error.message || error)
		}

		return feedbacks
	},
	async get({ commit }, feedbackId) {
		let feedback
		try {
			feedback = await API.get(`/feedbacks/${feedbackId}`)
			commit(types.SET_FEEDBACK, feedback)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			throw new Error(error.message || error)
		}

		return feedback
	},
	async update({ commit }, { feedbackId, data }) {
		this.dispatch('misc/setLoading', true)
		let feedback
		try {
			feedback = await API.update(`/feedbacks/${feedbackId}`, data)
			this.dispatch('misc/setLoading', false)
			commit(types.SET_FEEDBACK, feedback)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			this.dispatch('misc/setLoading', false)
			throw new Error(error.message || error)
		}

		return feedback
	},
	async remove({ commit }, feedbackId) {
		try {
			await API.remove(`/feedbacks/${feedbackId}`)
			commit(types.UNSET_FEEDBACK)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			throw new Error(error.message || error)
		}
	},
	async unset({ commit }) {
		commit(types.UNSET_FEEDBACK)
	},
	async unsetList({ commit }) {
		commit(types.UNSET_FEEDBACKS)
		this.dispatch('misc/setNext')
		this.dispatch('misc/setPrev')
	},
}

const mutations = {
	[types.SET_FEEDBACKS](state, feedbacks) {
		state.feedbacks = feedbacks
	},
	[types.UNSET_FEEDBACKS](state) {
		state.feedbacks = []
	},
	[types.SET_FEEDBACK](state, feedback) {
		state.feedback = feedback
		let idx = state.feedbacks.findIndex(c => c.feedbackId == feedback.feedbackId)
		if (idx > -1) {
			state.feedbacks[idx] = feedback
			Vue.set(state.feedbacks, idx, feedback)
		} else {
			state.feedbacks.push(cleanObject(feedback))
		}
	},
	[types.UNSET_FEEDBACK](state) {
		state.feedback = {}
	},
}

const getters = {
	feedbacks: state => {
		let feedbacks = state.feedbacks.map(f => {
			f.platformVersion = `${FeedbackPlatform.match(f.platform)} (${f.version})`
			f.brandDevice = `${f.brand} ${f.device}`
			let _split = f.content.split(` `)
			let _post = _split.length > 6 ? `...` : ``
			f.summary = _split.splice(0, 6).join(` `) + _post
			return f
		})
		return feedbacks
	},
	feedback: state => state.feedback,
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
}
