import { initializeApp, getApps, } from 'firebase/app'
import { getAuth, connectAuthEmulator, getMultiFactorResolver, } from 'firebase/auth'
import { getDatabase, connectDatabaseEmulator, ref as databaseRef, set } from 'firebase/database'
import { getStorage, connectStorageEmulator, ref as storageRef } from 'firebase/storage'
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check'
import { getAnalytics } from 'firebase/analytics'

import { FIREBASE } from '@/config'
const recaptchaKey = process.env.RECAPTCHA_KEY || process.env.VUE_APP_RECAPTCHA_KEY

export class FB {
	static instance

	constructor() {
		const apps = getApps()
		if (!apps.length) {
			const app = initializeApp(FIREBASE)

			this.appCheck = initializeAppCheck(app, {
				provider: new ReCaptchaV3Provider(recaptchaKey),
				isTokenAutoRefreshEnabled: true,
			})
			this.analytics = getAnalytics(app)
			this.auth = getAuth(app)
			this.database = getDatabase(app)
			this.storage = getStorage(app)

			if (location.hostname == 'localhost') {
				connectAuthEmulator(this.auth, 'http://localhost:3040')
				connectDatabaseEmulator(this.database, 'localhost', 3041)
				connectStorageEmulator(this.storage, 'localhost', 3044)
			}
		}
	}

	static getInstance() {
		if (!FB.instance) {
			FB.instance = new FB()
		}
		return FB.instance
	}

	setRefs(locationId, userId, terminal = 'A') {
		this.refs = {
			agreements: databaseRef(this.database, `agreements/${locationId}`),
			forms: databaseRef(this.database, `forms/${locationId}`),
			open: databaseRef(this.database, `open/${locationId}/${terminal}`),
			receipts: databaseRef(this.database, `receipts/${locationId}/${terminal}`),
			requests: databaseRef(this.database, `requests/${locationId}/${terminal}`),
			responses: databaseRef(this.database, `responses/${locationId}/${terminal}`),
			sms: databaseRef(this.database, `sms/${locationId}`),
			storage: storageRef(this.storage, `transfers/${locationId}`),
			transfers: databaseRef(this.database, `transfers/${locationId}/${terminal}`),
			user: databaseRef(this.database, `users/${userId}`),
		}
	}

	unsetRefs() {
		this.refs = {
			agreements: undefined,
			forms: undefined,
			open: undefined,
			receipts: undefined,
			requests: undefined,
			responses: undefined,
			sms: undefined,
			storage: undefined,
			transfers: undefined,
			user: undefined,
		}
	}
}

export const fb = FB.getInstance()
