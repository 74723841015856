import * as types from '../types'

import { API } from '@/lib/api'
import { fb } from '@/lib/fb'
import { flatten, cleanObject } from '@/lib/utils'

const state = () => ({
	locations: [],
	location: {},
	form: [],
	settings: {},
})

const actions = {
	async list({ commit }, params) {
		this.dispatch('misc/setLoading', true)

		let locations
		try {
			let { docs, prevParams, nextParams, } = await API.get(`/locations`, params)
			commit(types.SET_LOCATIONS, docs)
			locations = docs
			this.dispatch('misc/setLoading', false)
			this.dispatch('misc/setNext', nextParams)
			this.dispatch('misc/setPrev', prevParams)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			this.dispatch('misc/setLoading', false)
			throw new Error(error.message || error)
		}

		return locations
	},
	async get({ commit }, locationId) {
		let location
		try {
			location = await API.get(`/locations/${locationId}`)
			commit(types.SET_LOCATION, location)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			throw new Error(error.message || error)
		}

		return location
	},
	async update({ commit }, { locationId, data }) {
		this.dispatch('misc/setLoading', true)

		let location
		try {
			location = await API.update(`/locations/${locationId}`, data)
			this.dispatch('misc/setLoading', false)
			commit(types.SET_LOCATION, location)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			this.dispatch('misc/setLoading', false)
			throw new Error(error.message || error)
		}

		return location
	},
	async remove({ commit }, locationId) {
		try {
			await API.remove(`/locations/${locationId}`)
			commit(types.UNSET_LOCATION)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			throw new Error(error.message || error)
		}
	},
	async create({ commit }, data) {
		this.dispatch('misc/setLoading', true)

		let location
		try {
			location = await API.send(`/locations`, data)
			this.dispatch('misc/setLoading', false)
			commit(types.SET_LOCATION, location)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			this.dispatch('misc/setLoading', false)
			// throw new Error(error.message || error)
		}

		return location
	},
	async unset({ commit }) {
		commit(types.UNSET_LOCATION)
	},
	async createAgreement({ commit, }, data) {
		this.dispatch('misc/setLoading', true)

		let agreement
		try {
			agreement = await API.send(`/agreements`, data)
			this.dispatch('misc/setLoading', false)
			commit(types.SET_AGREEMENT, agreement)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			this.dispatch('misc/setLoading', false)
			// throw new Error(error.message || error)
		}

		return agreement
	},
	async getForm({ commit }, locationId) {
		this.dispatch('misc/setLoading', true)

		let form
		try {
			form = await API.get(`/locations/${locationId}/forms`,)
			this.dispatch('misc/setLoading', false)
			commit(types.SET_FORM, form)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			this.dispatch('misc/setLoading', false)
			// throw new Error(error.message || error)
		}

		return form
	},
	async updateForm({ commit }, {locationId, form, }) {
		this.dispatch('misc/setLoading', true)

		let update
		try {
			update = await API.update(`/locations/${locationId}/forms`, {form})
			this.dispatch('misc/setLoading', false)
			form = JSON.parse(form)
			commit(types.SET_FORM, form)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			this.dispatch('misc/setLoading', false)
			// throw new Error(error.message || error)
		}

		return form
	},
	async getSettings({ commit }, locationId) {
		this.dispatch('misc/setLoading', true)

		let settings
		try {
			settings = await API.get(`/locations/${locationId}/settings`,)
			this.dispatch('misc/setLoading', false)
			commit(types.SET_SETTINGS, settings)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			this.dispatch('misc/setLoading', false)
			// throw new Error(error.message || error)
		}

		return settings
	},
	async updateSettings({ commit }, { locationId, settings, }) {
		this.dispatch('misc/setLoading', true)

		let update
		try {
			update = await API.update(`/locations/${locationId}/settings`, {settings})
			this.dispatch('misc/setLoading', false)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			this.dispatch('misc/setLoading', false)
			// throw new Error(error.message || error)
		}

		return settings
	},
}

const mutations = {
	[types.SET_LOCATIONS](state, locations) {
		state.locations = locations
	},
	[types.UNSET_LOCATIONS](state) {
		state.locations = []
	},
	[types.SET_LOCATION](state, location) {
		state.location = location
		let idx = state.locations.findIndex(c => c.locationId == location.locationId)
		if (idx > -1) {
			state.locations[idx] = location
		} else {
			state.locations.push(cleanObject(location))
		}
	},
	[types.UNSET_LOCATION](state) {
		state.location = {}
		state.form = []
		state.settings = {}
	},
	[types.SET_FORM](state, form) {
		state.form = form
	},
	[types.SET_SETTINGS](state, settings) {
		state.settings = settings
	}
}

const getters = {
	locations: state => state.locations,
	location: state => state.location,
	form: state => state.form,
	settings: state => state.settings,
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
}
