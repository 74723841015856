///
/// VUE ROUTER
///

/// DEPENDENCIES
import Vue from 'vue'
import VueRouter from 'vue-router'
import { routes } from './routes'
import { store } from '../store'

/// INSTALL
Vue.use(VueRouter)

/// EXPORT ROUTER
export const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior (to, from, saved) {
		if (saved) {
			return saved
		}

		return { x: 0, y: 0 }
	}
})

///
/// Preload asyncData before route change
///
router.beforeResolve((to, from, next) => {

	const matched = router.getMatchedComponents(to)
	const prevMatched = router.getMatchedComponents(from)

	let diffed = false
	const activated = matched.filter((c, i) => {
		return diffed || (diffed = (prevMatched[i] !== c))
	})

	if (!activated.length) {
		return next()
	}

	Promise.all(activated.map(c => {
		if (c && c.asyncData) {
			return c.asyncData({ store, route: to })
		}
	})).then(() => {
		// Reset Errors on route change
		store.dispatch('misc/setError', null)
		// store.dispatch('user/autoLogout')
		next()
	}).catch(err => {
		console.error(`Route Error `, err)
		next({ name: 'login'} )
	})
})

