/**
 * Vue Filter - Enums
 */

// Dependencies
import * as _enums from '@/lib/enums'

/**
 * Return enumerated string from key/value.
 * @param {mixed} val Value
 * @param {string} key Enum key
 */
export default function enums(val, key, reverse = false) {

	let opts = _enums[key]

	if (!opts) {
		return val
	}
	if (!opts.match(val)) return val
	return opts.match(val)
	// if (!opts.tryParse(val)) return val
	// return opts.tryParse(val).description
}
