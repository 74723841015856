
const fromJson = (data) => {
	let parsed = {}
	try {
		parsed = JSON.parse(String(data))
	} catch (error) {
		return Object(data)
	}

	return parsed
}

// Config Exports
export const FIREBASE = fromJson(process.env.FIREBASE_CONFIG || process.env.VUE_APP_FIREBASE_CONFIG)
