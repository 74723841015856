export default function fieldMap(input, provider, reverse = false) {
	let output = input
	let map = {}

	switch(provider) {
		case 'drchrono':
			map = {
				firstName: 'first_name',
				lastName: 'last_name',
				middleName: 'middle_name',
				email: 'email',
				dob: 'date_of_birth',
				ssn: 'social_security_number',
				street: 'address',
				city: 'city',
				state: 'state',
				zip: 'zip_code',
				employer: 'employer',
				emergencyFirstName: 'emergency_contact_name',
				emergencyPhone: 'emergency_contact_phone',
				emergencyRelation: 'emergency_contact_relation',
				phone: 'cell_phone',
				phone2: 'home_phone',
				photo: 'patient_photo',
			}
			break
		case 'athena':
			map = {
				firstName: 'firstname',
				lastName: 'lastname',
				dob: 'dob',
				patientid: 'patientid',
				gender: 'sex',
				street: 'address1',
				city: 'city',
				state: 'state',
				zip: 'zip',
				phone: 'contacthomephone',
			}
			break
		case 'modmed':
			map = {
				firstName: 'firstName',
				lastName: 'lastName',
				dob: 'dob',
				gender: 'gender',

			}
			break
	}

	for (const key in map) {
		let rekey = reverse ? key : map[key]
		let okey = reverse ? map[key] : key
		if (input[okey]) {
			output[rekey] = input[okey]
		}
	}

	return output
}
