///
/// Vue Router - Routes
///

import { store } from '@/lib/store'

/// Views
const Main = () => import(/* webpackChunkName: "main" */ '@/views/Main')
const Auth = () => import(/* webpackChunkName: "auth" */ '@/views/Auth')

/// Components
const MainHeader = () => import(/* webpackChunkName: "main" */ '@/components/MainHeader')
const MainFooter = () => import(/* webpackChunkName: "main" */ '@/components/MainFooter')

const ModalAgreement = () => import(/* webpackChunkName: "agreements" */ '@/components/ModalAgreement')
const ModalClient = () => import(/* webpackChunkName: "clients" */ '@/components/ModalClient')
const ModalFeedback = () => import(/* webpackChunkName: "feedback" */ '@/components/ModalFeedback')
const ModalForm = () => import(/* webpackChunkName: "locations" */ '@/components/ModalForm')
const ModalLocation = () => import(/* webpackChunkName: "locations" */ '@/components/ModalLocation')
const ModalSettings = () => import(/* webpackChunkName: "locations" */ '@/components/ModalSettings')
const ModalUser = () => import(/* webpackChunkName: "users" */ '@/components/ModalUser')

/// Partials
const Account = () => import(/* webpackChunkName: "account" */ '@/views/partials/Account')
const Checkins = () => import(/* webpackChunkName: "main" */ '@/views/partials/Checkins')
const Checkin = () => import(/* webpackChunkName: "main" */ '@/views/partials/Checkin')
const AuthLogin = () => import(/* webpackChunkName: "auth" */ '@/views/partials/AuthLogin')
const AuthReset = () => import(/* webpackChunkName: "auth" */ '@/views/partials/AuthReset')
const Clients = () => import(/* webpackChunkName: "clients" */ '@/views/partials/Clients')
const Locations = () => import(/* webpackChunkName: "locations" */ '@/views/partials/Locations')
const Client = () => import(/* webpackChunkName: "client" */ '@/views/partials/Client')
const Users = () => import(/* webpackChunkName: "users" */ '@/views/partials/Users')
const Feedbacks = () => import(/* webpackChunkName: "feedbacks" */ '@/views/partials/Feedbacks')

const mainComponents = (content, modal) => ({
	header: MainHeader,
	footer: MainFooter,
	content,
	modal,
})

const authComponents = (content) => ({
	content,
})

// Functions
async function authGuard (to, from, next) {
	await store.dispatch('user/loadUser')
	const _me = store.getters['user/me']
	if (_me && Object.keys(_me).length) {
		if (_me.locationId) {
			next()
		}
	} else {
		next({ name: 'login', query: { from: to.name }} )
	}
}

/// Exports
export const routes = [
	{
		path: '/',
		component: Main,
		beforeEnter: authGuard,
		children: [
			{
				name: 'main',
				path: '',
				components: mainComponents(Checkins),
			},
			{
				name: 'account',
				path: 'account',
				components: mainComponents(Account),
			},
			{
				name: 'checkin',
				path: 'checkin/:id',
				components: mainComponents(Checkin),
			},
			{
				name: 'users',
				path: 'users',
				components: mainComponents(Users),
				children: [
					{
						name: 'user',
						path: '/user/:uid?',
						components: {
							pageModal: ModalUser,
						},
						meta: {
							modalTitle: `Manage User`,
						},
					},
				],
			},
			{
				name: 'clients',
				path: 'clients',
				components: mainComponents(Clients),
				children: [
					{
						name: 'client',
						path: '/client/:clientId?',
						components: {
							pageModal: ModalClient,
						},
						meta: {
							modalTitle: `Manage Client`,
						},
					},
				],
			},
			{
				name: 'locations',
				path: 'locations',
				components: mainComponents(Locations),
				children: [
					{
						name: 'location',
						path: '/location/:locationId?',
						components: {
							pageModal: ModalLocation,
						},
						meta: {
							modalTitle: `Manage Location`,
						},
					},
					{
						name: 'agreement',
						path: '/agreement/:locationId/:agreementId?',
						components: {
							pageModal: ModalAgreement,
						},
						meta: {
							modalTitle: 'Manage Agreement',
						},
					},
					{
						name: 'form',
						path: '/form/:locationId/:formId?',
						components: {
							pageModal: ModalForm,
						},
						meta: {
							modalTitle: 'Manage Form',
						},
					},
					{
						name: 'settings',
						path: '/settings/:locationId',
						components: {
							pageModal: ModalSettings,
						},
						meta: {
							modalTitle: 'Manage Settings',
						},
					}
				],
			},
			{
				name: 'feedbacks',
				path: 'feedbacks',
				components: mainComponents(Feedbacks),
				children: [
					{
						name: 'feedback',
						path: '/feedback/:feedbackId?',
						components: {
							pageModal: ModalFeedback,
						},
						meta: {
							modalTitle: `Manage Feedback`,
						},
					},
				],
			},
		],
	},
	{
		path: '/login',
		component: Auth,
		children: [
			{
				name: 'login',
				path: '',
				components: authComponents(AuthLogin),
			},
			{
				name: 'reset',
				path: 'reset',
				components: authComponents(AuthReset),
			},
		],
	}
]
