///
/// Vuex Module - Navigation
///

import * as types from '../types'

/// STATE
const state = () => ({
	isToggled: false,
	menus: {
		user: [
			{
				title: `Account`,
				icon: `person`,
				link: {
					name: 'account',
				},
			}
		],
		manager: [
			{
				title: `Users`,
				icon: `groups`,
				link: {
					name: 'users',
				},
			},
		],
		client: [
			{
				title: `Locations`,
				icon: `meeting_room`,
				link: {
					name: 'locations',
				},
			},
		],
		internal: [
			{
				title: `Clients`,
				icon: `masks`,
				link: {
					name: 'clients',
				},
			},
		],
		admin: [
			{
				title: `Feedback`,
				icon: `help`,
				link: {
					name: `feedbacks`,
				},
			},
		],
	}
})

const actions = {
	menuToggle(context, status) {
		if (!status) {
			status = !context.state.isToggled
		}
		context.commit(types.MENU_TOGGLE, status)
	},
}

const mutations = {
	[types.MENU_TOGGLE](state, status) {
		state.isToggled = status
	}
}

const getters = {
	isToggled: state => state.isToggled,
	menu: state => key => {
		return state.menus[key]
	}
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
}
