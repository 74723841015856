
export class Auth {
	static instance
	static user

	constructor() {

	}

	getInstance() {
		if (!Auth.instance) {
			Auth.instance = new Auth()
		}

		return Auth.instance
	}
}
