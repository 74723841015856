///
/// Store Action Types
///

/// MENU
export const MENU_TOGGLE = 'MENU_TOGGLE'

/// MISC
export const SET_LOADING = 'SET_LOADING'
export const SET_ERROR = 'SET_ERROR'
export const SET_NEXT = 'SET_NEXT'
export const SET_PREV = 'SET_PREV'

/// AUTHENTICATION
export const LOGIN_USER = 'LOGIN_USER'
export const LOGOUT_USER = 'LOGOUT_USER'
export const SET_LOGIN = 'SET_LOGIN'
export const UNSET_LOGIN = 'UNSET_LOGIN'
export const SET_ME = 'SET_ME'
export const UNSET_ME = 'UNSET_ME'
export const SET_USER = 'SET_USER'
export const UNSET_USER = 'UNSET_USER'
export const SET_PROFILE = 'SET_PROFILE'
export const SET_LOGIN_STEP = 'SET_LOGIN_STEP'
export const SET_LOGIN_LOCATION = 'SET_LOGIN_LOCATION'
export const UNSET_LOGIN_LOCATION = 'UNSET_LOGIN_LOCATION'
export const SET_LOGIN_LOCATIONS = 'SET_LOGIN_LOCATIONS'
export const UNSET_LOGIN_LOCATIONS = 'UNSET_LOGIN_LOCATIONS'

/// USERS
export const SET_USERS = 'SET_USERS'
export const UNSET_USERS = 'UNSET_USERS'

/// CLIENT
export const SET_CLIENT = 'SET_CLIENT'
export const SET_CLIENTS = 'SET_CLIENTS'
export const UNSET_CLIENT = 'UNSET_CLIENT'
export const UNSET_CLIENTS = 'UNSET_CLIENTS'

/// FEEDBACK
export const SET_FEEDBACK = 'SET_FEEDBACK'
export const SET_FEEDBACKS = 'SET_FEEDBACKS'
export const UNSET_FEEDBACK = 'UNSET_FEEDBACK'
export const UNSET_FEEDBACKS = 'UNSET_FEEDBACKS'

/// LOCATION
export const SET_LOCATION = 'SET_LOCATION'
export const SET_LOCATIONS = 'SET_LOCATIONS'
export const UNSET_LOCATION = 'UNSET_LOCATION'
export const UNSET_LOCATIONS = 'UNSET_LOCATIONS'

/// AGREEMENTS
export const SET_AGREEMENT = 'SET_AGREEMENT'
export const SET_AGREEMENTS = 'SET_AGREEMENTS'
export const UNSET_AGREEMENT = 'UNSET_AGREEMENT'
export const UNSET_AGREEMENTS = 'UNSET_AGREEMENTS'

/// FORMS
export const SET_FORM = 'SET_FORM'
export const SET_FORMS = 'SET_FORMS'
export const UNSET_FORM = 'UNSET_FORM'
export const UNSET_FORMS = 'UNSET_FORMS'

/// SETTINGS
export const SET_SETTING = 'SET_SETTING'
export const SET_SETTINGS = 'SET_SETTINGS'
export const UNSET_SETTING = 'UNSET_SETTING'
export const UNSET_SETTINGS = 'UNSET_SETTINGS'

/// TRANSFERS
export const SET_OPEN = 'SET_OPEN'
export const UNSET_OPEN = 'UNSET_OPEN'
export const SET_TRANSFER_STATUS = 'SET_TRANSFER_STATUS'
export const SET_WATCH = 'SET_WATCH'
export const UNSET_WATCH = 'UNSET_WATCH'

export const ADD_CHECKIN = 'ADD_CHECKIN'
export const SET_CHECKIN = 'SET_CHECKIN'
export const UNSET_CHECKIN = 'UNSET_CHECKIN'
export const SET_CHECKINS = 'SET_CHECKINS'
export const UNSET_CHECKINS = 'UNSET_CHECKINS'
export const DELETE_CHECKIN = 'DELETE_CHECKIN'
export const UPDATE_CHECKIN = 'UPDATE_CHECKIN'
export const SET_CHECKIN_LOADING = 'SET_CHECKIN_LOADING'

export const ENABLE_NOTIFICATIONS = 'ENABLE_NOTIFICATIONS'
export const SEND_NOTIFICATION = 'SEND_NOTIFICATION'
