import { startOfToday } from 'date-fns'
import Dexie from 'dexie'
import { v4 } from 'uuid'
import * as types from '../types'
import { parseImage, mergeImage, formFields } from '@/lib/utils'
import { fb } from '@/lib/fb'
import { logEvent, setUserProperties, } from 'firebase/analytics'

import { CheckinStatus } from '@/lib/enums'

const db = new Dexie('qpCheckins')
db.version(1).stores({ checkins: 'id, createdAt' })

const DEFAULTS = {
	checkins: [],
	checkin: {},
	forms: [],
}

const state = () => ({
	...DEFAULTS,
	...{
		db,
	}
})

const actions = {
	async init({ dispatch, state, }) {
		await state.db.open()
		// await dispatch('checkins/prune')
		// await dispatch('checkins/loadCheckins')
	},
	async prune({ state }) {
		const cutoff = startOfToday().getTime()
		const checkins = await state.db.checkins
			.where('createdAt')
			.below(cutoff)
			.toArray()
		// return checkins.forEach(async (checkin) => {
		// 	await state.db.checkins.delete(checkin.id)
		// })
	},
	async loadCheckins({ commit,  state, dispatch, }) {
		const checkins = await state.db.checkins
			.orderBy('createdAt')
			.toArray()

		return commit(types.SET_CHECKINS, checkins)
	},
	async loadCheckin({ commit, state, }, id) {
		this.dispatch('misc/setLoading', true)
		const checkin = await state.db.checkins.get(id)
		this.dispatch('misc/setLoading', false)
		commit(types.SET_CHECKIN, checkin)
		return checkin
	},
	unloadCheckins({ commit }) {
		commit(types.UNSET_CHECKINS)
	},
	unloadCheckin({ commit }) {
		commit(types.UNSET_CHECKIN)
	},
	async addCheckin({ commit, state, }, patient) {
		const uuid = v4()

		const checkin = {
			...patient,
			...{
				id: uuid,
				status: CheckinStatus.New,
				createdAt: (new Date()).getTime(),
				expiresAt: (new Date()).getTime() + (1000 * 60 * 60 * 24),
			}
		}

		state.db.checkins.put(checkin)
			.then(() => {
				commit(types.ADD_CHECKIN, checkin)
				// TODO: Notification
			})
	},
	async updateCheckin({ commit, state, }, checkin) {
		const update = await state.db.checkins.update(checkin.id, checkin)
		if (update) {
			return commit(types.UPDATE_CHECKIN, checkin)
		}
	},
	async deleteCheckin({ commit, state, }, checkin) {
		const deleted = await state.db.checkins.delete(checkin.id)
		if (deleted) {
			return commit(types.DELETE_CHECKIN, checkin)
		}
	},
	async acceptCheckin({ commit, state, }, checkin) {
		checkin.status = CheckinStatus.Accepted
		checkin.acceptedAt = (new Date()).getTime()
		const update = await state.db.checkins.update(checkin.id, checkin)
		if (update) {
			return commit(types.UPDATE_CHECKIN, checkin)
		}
	},
	async transferCheckin({ commit, state, rootGetters, }, checkin) {
		const user = rootGetters['user/user']
		if (!user || !user.location || !user.uid) return
	},
}

const mutations = {
	[types.SET_CHECKINS](state, checkins) {
		state.checkins = checkins
	},
	[types.SET_CHECKIN](state, checkin) {
		state.checkin = checkin
	},
	[types.UNSET_CHECKINS](state) {
		state.checkins = DEFAULTS.checkins
	},
	[types.UNSET_CHECKIN](state) {
		state.checkin = DEFAULTS.checkin
	},
	[types.ADD_CHECKIN](state, checkin) {
		state.checkins.push(checkin)
	},
	[types.UPDATE_CHECKIN](state, checkin) {
		state.checkin = checkin
		let idx = state.checkins.findIndex(s => s.id == checkin.id)
		if (idx > -1) {
			state.checkins[idx] = checkin
		}
	},
	[types.DELETE_CHECKIN](state, checkin) {
		state.checkin = DEFAULTS.checkin
		let idx = state.checkins.findIndex(s => s.id == checkin.id)
		if (idx > -1) {
			delete state.checkins[idx]
		}
	},
}

const getters = {
	checkins: state => state.checkins.map(c => {
		c.name = [c.firstName, c.lastName].join(` `)
		return c
	}),
	checkin: state => state.checkin,
}


export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
}
