
function Enum(items) {
	let members = Object.create(null)

	if (Array.isArray(items)) {
		items.forEach((item, idx) => {
			members[item] = idx
		})
	} else {
		for (const key in items) {
			Reflect.set(members, key, items[key])
			// members[key] = Symbol(items[key])
		}
	}

	members.match = val => {
		return Object.keys(members).find(k => members[k] === val)
	}

	members.toDropdown = () => {
		let items = []
		for (const k in members) {
			if (!Object.hasOwnProperty.bind(members)(k)) continue
			if (typeof members[k] == 'function') continue
			items.push({
				label: k,
				value: members[k]
			})
		}
		return items
	}

	return new Proxy(members, {
		get: (target, name, receiver) => {
			if (!members[name]) {
				console.warn(`Enum '${name}' not found.`)
				return name
			}
			return Reflect.get(target, name, receiver)
		},
		set: (target, name, value) => {
			throw new TypeError(`Added to Enum not allowed.`)
		}
	})
}

export const UserStatus = Enum({
	Active: 'active',
	Inactive: 'inactive',
	Pending: 'pending',
	Removed: 'removed',
	Trial: 'trial',
})

export const TransferStatus = Enum({
	Incoming: 'incoming',
	Fail: 'fail',
	Connecting: 'connecting',
	Transferring: 'transferring',
	Transferred: 'transferred',
	Success: 'success',
	Cancelled: 'cancelled',
})

export const CheckinStatus = Enum({
	New: 'new',
	Accepted: 'accepted',
	Importing: 'importing',
	Imported : 'imported',
	Transferred: 'transferred',
	Removed: 'removed',
})

export const UserLevel = Enum({
	Admin: 'admin',
	Client: 'client',
	Employee: 'employee',
	Internal: 'internal',
	Manager: 'manager',
})

export const ClientStatus = Enum({
	Active: 'active',
	Inactive: 'inactive',
	Pending: 'pending',
	Removed: 'removed',
	Trial: 'trial',
})

export const LocationStatus = Enum({
	Active: 'active',
	Inactive: 'inactive',
	Pending: 'pending',
	Removed: 'removed',
	Trial: 'trial',
})

export const AgreementStatus = Enum({
	Active: 'active',
	Inactive: 'inactive',
	Pending: 'pending',
	Removed: 'removed',
	Trial: 'trial',
})

export const FormStatus = Enum({
	Active: 'active',
	Inactive: 'inactive',
	Pending: 'pending',
	Removed: 'removed',
	Trial: 'trial',
})

export const LoginStep = Enum({
	Login: 'login',
	Location: 'location',
	Twofactor: 'twofactor',
	Reset: 'reset',
})

export const FeedbackStatus = Enum({
	Unread: 'unread',
	Read: 'read',
	Archived: 'archived',
	Removed: 'removed'
})

export const FeedbackType = Enum({
	Feedback: 'feedback',
	Bug: 'bug',
})

export const FeedbackPlatform = Enum({
	Android: 'android',
	Apple: 'apple',
})

export const AuthError = Enum({
	MISSING_CREDENTIALS: 'Missing login credentials',
	INVALID_EMAIL: 'Please enter a valid email address',
	INVALID_PASSWORD: 'Invalid login credentials',
	MISSING_MFA: 'Please register your phone number for authentication',
	NEEDS_MFA: 'Please authenticate your account',
	INVALID_MFA: 'Invalid authentication code',
	LOCKED: 'Your account has been locked due to invalid password attempts',
})

export const OtpMethod = Enum({
	hotp: 'hotp',
	totp: 'totp',
})
