import * as types from '../types'

import { API } from '@/lib/api'
import { fb } from '@/lib/fb'
import { flatten, cleanObject } from '@/lib/utils'

const state = () => ({
	agreements: [],
	agreement: {},

})

const actions = {
	async list({ commit }, data) {
		this.dispatch('misc/setLoading', true)
		const { locationId, params } = data
		let agreements
		try {
			agreements = await API.get(`/agreements/${locationId}`, params)
			commit(types.SET_AGREEMENTS, agreements)
			this.dispatch('misc/setLoading', false)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			this.dispatch('misc/setLoading', false)
			throw new Error(error.message || error)
		}

		return agreements
	},
	async get({ commit }, data) {
		let agreement
		try {
			const { agreementId, locationId } = data
			agreement = await API.get(`/agreements/${locationId}/${agreementId}`)
			commit(types.SET_AGREEMENT, agreement)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			throw new Error(error.message || error)
		}

		return agreement
	},
	async update({ commit }, { agreementId, data }) {
		this.dispatch('misc/setLoading', true)

		let agreement
		try {
			agreement = await API.update(`/agreements/${agreementId}`, data)
			this.dispatch('misc/setLoading', false)
			commit(types.SET_AGREEMENT, agreement)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			this.dispatch('misc/setLoading', false)
			throw new Error(error.message || error)
		}

		return agreement
	},
	async remove({ commit }, agreementId) {
		try {
			await API.remove(`/agreements/${agreementId}`)
			commit(types.UNSET_AGREEMENT)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			throw new Error(error.message || error)
		}
	},
	async create({ commit }, data) {
		this.dispatch('misc/setLoading', true)

		let agreement
		try {
			agreement = await API.send(`/agreements`, data)
			this.dispatch('misc/setLoading', false)
			commit(types.SET_AGREEMENT, agreement)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			this.dispatch('misc/setLoading', false)
			throw new Error(error.message || error)
		}

		return agreement
	},
	async unset({ commit }) {
		commit(types.UNSET_AGREEMENT)
	},
}

const mutations = {
	[types.SET_AGREEMENTS](state, agreements) {
		state.agreements = agreements
	},
	[types.UNSET_AGREEMENTS](state) {
		state.agreements = []
	},
	[types.SET_AGREEMENT](state, agreement) {
		state.agreement = agreement
		let idx = state.agreements.findIndex(c => c.agreementId == agreement.agreementId)
		if (idx > -1) {
			state.agreements[idx] = agreement
		} else {
			state.agreements.push(cleanObject(agreement))
		}
	},
	[types.UNSET_AGREEMENT](state) {
		state.agreement = {}
	},
}

const getters = {
	agreements: state => state.agreements,
	agreement: state => state.agreement,
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
}
