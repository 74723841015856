import sodium from 'libsodium-wrappers'

export class Secure {
	static instance
	keyPair

	constructor() {
		this.keyPair = {}
	}

	static getInstance() {
		if (!Secure.instance) {
			Secure.instance = new Secure()
		}

		return Secure.instance
	}

	async generateKeypair() {
		await sodium.ready

		this.keyPair = sodium.crypto_box_keypair()
		return this.keyPair
	}

	async parseKeys(requestKey) {
		await sodium.ready

		const publicKey = requestKey.slice(0, sodium.crypto_box_PUBLICKEYBYTES)
		const signKey = requestKey.slice(sodium.crypto_box_PUBLICKEYBYTES, requestKey.length)

		const requestPublicKey = new Uint8Array(publicKey)
		const requestSignKey = new Uint8Array(signKey)

		return { requestPublicKey, requestSignKey }
	}

	async parseEncryptedString(data) {
		await sodium.ready

		const encryptedString = data.split('|')[0]
		const signedString = data.split('|')[1]

		const encrypted = sodium.from_hex(encryptedString)
		const signed = sodium.from_hex(signedString)

		const nonce = encrypted.slice(0, sodium.crypto_box_NONCEBYTES)
		const message = encrypted.slice(sodium.crypto_box_NONCEBYTES, encrypted.length)

		return { nonce, message, signed }
	}

	async parseNonce(data) {
		await sodium.ready

		const encrypted = sodium.from_hex(data)

		const nonce = encrypted.slice(0, sodium.crypto_box_NONCEBYTES)
		const message = encrypted.slice(sodium.crypto_box_NONCEBYTES, encrypted.length)

		return { nonce, message }
	}

	async decrypt(message, signature, nonce, publicKey, privateKey, signKey) {
		await sodium.ready

		let patientData = ''
		try {
			const decrypted = sodium.crypto_box_open_easy(message, nonce, publicKey, privateKey)
			let verified = false

			try {
				verified = await sodium.crypto_sign_verify_detached(signature, decrypted, signKey)
			} catch (error) {
				throw error
			}

			if (!verified) {
				throw new Error(`Can not verify signature`)
			}

			try {
				patientData = sodium.to_string(decrypted)
			} catch (error) {
				throw error
			}
		} catch (error) {
			throw error
		}

		return patientData
	}

	async decryptImage(message, nonce, publicKey, privateKey) {
		await sodium.ready

		let imgString = ''
		try {
			const decrypted = sodium.crypto_box_open_easy(message, nonce, publicKey, privateKey)
			imgString = sodium.to_string(decrypted)
		} catch (error) {
			throw error
		}


		return imgString
	}
}
