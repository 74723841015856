import * as types from '../types'

import { API } from '@/lib/api'
import { fb } from '@/lib/fb'
import { flatten, cleanObject } from '@/lib/utils'

const state = () => ({
	clients: [],
	client: {},

})

const actions = {
	async list({ commit }, params) {
		this.dispatch('misc/setLoading', true)

		let clients
		try {
			let { docs, prevParams, nextParams, } = await API.get(`/clients`, params)
			commit(types.SET_CLIENTS, docs)
			clients = docs
			this.dispatch('misc/setLoading', false)
			this.dispatch('misc/setNext', nextParams)
			this.dispatch('misc/setPrev', prevParams)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			this.dispatch('misc/setLoading', false)
			throw new Error(error.message || error)
		}

		return clients
	},
	async get({ commit }, clientId) {
		let client
		try {
			client = await API.get(`/clients/${clientId}`)
			commit(types.SET_CLIENT, client)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			throw new Error(error.message || error)
		}

		return client
	},
	async update({ commit }, { clientId, data }) {
		this.dispatch('misc/setLoading', true)
		let client
		try {
			client = await API.update(`/clients/${clientId}`, data)
			this.dispatch('misc/setLoading', false)
			commit(types.SET_CLIENT, client)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			this.dispatch('misc/setLoading', false)
			throw new Error(error.message || error)
		}

		return client
	},
	async remove({ commit }, clientId) {
		try {
			await API.remove(`/clients/${clientId}`)
			commit(types.UNSET_CLIENT)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			throw new Error(error.message || error)
		}
	},
	async create({ commit }, data) {
		this.dispatch('misc/setLoading', true)

		let client
		try {
			client = await API.send(`/clients`, data)
			this.dispatch('misc/setLoading', false)
			commit(types.SET_CLIENT, client)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			this.dispatch('misc/setLoading', false)
			throw new Error(error.message || error)
		}

		return client
	},
	async unset({ commit }) {
		commit(types.UNSET_CLIENT)
	},
	async unsetList({ commit }) {
		commit(types.UNSET_CLIENTS)
		this.dispatch('misc/setNext')
		this.dispatch('misc/setPrev')
	},
}

const mutations = {
	[types.SET_CLIENTS](state, clients) {
		state.clients = clients
	},
	[types.UNSET_CLIENTS](state) {
		state.clients = []
	},
	[types.SET_CLIENT](state, client) {
		state.client = client
		let idx = state.clients.findIndex(c => c.clientId == client.clientId)
		if (idx > -1) {
			state.clients[idx] = client
		} else {
			state.clients.push(cleanObject(client))
		}
	},
	[types.UNSET_CLIENT](state) {
		state.client = {}
	},
	[types.UNSET_CLIENTS](state) {
		state.clients = []
	},
}

const getters = {
	clients: state => state.clients,
	client: state => {
		if(state.client.provider) {
			try {
				// state.client.provider = JSON.stringify(state.client.provider, null, 4)
			} catch (error) {

			}
		}

		return state.client
	},
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
}
