import add from 'date-fns/add'

import * as types from '../types'

import { API } from '@/lib/api'
import { fb } from '@/lib/fb'
import { LoginStep, AuthError, } from '@/lib/enums'
import { router } from '@/lib/router'
import { logEvent, setUserProperties, } from 'firebase/analytics'
import { signOut, setPersistence, signInWithCustomToken, signInWithEmailAndPassword, browserSessionPersistence, inMemoryPersistence, onAuthStateChanged, getIdTokenResult, AuthErrorCodes, getMultiFactorResolver } from 'firebase/auth'

// Definitions
const logoutDuration = 45
const warningDuration = 30
const logoutLength = logoutDuration * (60000)
const warningLength = warningDuration * (60000)

const state = () => ({
	loginStep: LoginStep.Login,
	me: {},
	logoutTimer: 0,
	logoutWarning: 0,
	timeLogout: add((new Date()), { minutes: logoutDuration }),
	timeWarning: add((new Date()), { minutes: warningDuration }),
	user: {},
	users: [],
	locations: [],
	location: {},
})

const actions = {
	async login({ commit, }, credentials ) {
		this.dispatch('misc/setError', null)
		this.dispatch('misc/setLoading', true)
		this.dispatch('checkin/prune')

		try {
			const { email, password, recaptchaToken, } = credentials
			const login = await API.send(`/users/login`, { email, password, recaptchaToken, }, false)
			const { token, needLocation, needOtp, } = login
			if (!token) {
				throw new Error(`Can not retrieve authentication`)
			}

			if (needOtp) {
				commit(types.SET_LOGIN, login)
				await setPersistence(fb.auth, inMemoryPersistence)
				await signInWithCustomToken(fb.auth, token)
				return commit(types.SET_LOGIN_STEP, LoginStep.Twofactor)
			}
			if (needLocation) {
				commit(types.SET_LOGIN, login)
				await setPersistence(fb.auth, inMemoryPersistence)
				await signInWithCustomToken(fb.auth, token)
				return commit(types.SET_LOGIN_STEP, LoginStep.Location)
			}

			await setPersistence(fb.auth, browserSessionPersistence)
			await signInWithCustomToken(fb.auth, token)
			router.push({name: 'main'})
			commit(types.UNSET_LOGIN)
		} catch (error) {
			commit(types.UNSET_LOGIN)
			await signOut(fb.auth)
			this.dispatch('misc/setError', error)
		} finally {
			this.dispatch('misc/setLoading', false)
		}
	},
	async otp({ commit, dispatch, }, data) {
		this.dispatch('misc/setError', null)
		this.dispatch('misc/setLoading', true)

		try {
			const { code, secret, } = data
			const login = await API.send(`/users/otp`, { code, secret, })
			const { token, needLocation } = login

			if (!code) {
				return
			}

			if (needLocation) {
				commit(types.SET_LOGIN, login)
				await setPersistence(fb.auth, inMemoryPersistence)
				await signInWithCustomToken(fb.auth, token)
				return commit(types.SET_LOGIN_STEP, LoginStep.Location)
			}

			await setPersistence(fb.auth, browserSessionPersistence)
			await signInWithCustomToken(fb.auth, token)
			router.push({ name: 'main', })
			commit(types.UNSET_LOGIN)
		} catch (error) {
			console.error(error)
			commit(types.UNSET_LOGIN)
			this.dispatch('misc/setError', error)
		} finally {
			this.dispatch('misc/setLoading', false)
		}
	},
	async loadUser({ commit, dispatch, }) {
		let authUser
		try {
			authUser = await new Promise((resolve, reject) => {
				onAuthStateChanged(fb.auth, (userState) => {
					if (userState) {
						resolve(userState)
					} else {
						reject(`No user`)
					}
				})
			})
		} catch (error) {
			console.log('CATCH', error)
		}

		if (authUser) {
			const token = await getIdTokenResult(authUser)
			const { locationId, permissions, qp, uid, terminal, } = token.claims
			if (!qp) {
				throw new Error(`Missing token information`)
			}
			const { c, l, p, u, } = qp
			authUser = {
				...authUser,
				...u,
				...{
					client: c,
					location: l,
					provider: p,
				},
				locationId,
				terminal: terminal || 'A',
				permissions,
			}

			if (locationId) {
				fb.setRefs(locationId, authUser.uid, terminal, )
			}

			if (l && l.locationId) {
				try {
					setUserProperties(fb.analytics, {
						client: c.name,
						clientId: c.clientId,
						locationId: locationId,
						location: l.name,
						uid,
					})
				} catch (error) {
					console.warn(`Can not set user properties:`, error)
				}
			}
		} else {
			fb.unsetRefs()
		}

		return commit(types.SET_ME, authUser)
	},
	async getTokenData({ commit }) {
		this.dispatch('misc/setError', null)
		this.dispatch('misc/setLoading', true)

		const tokenData = await API.get(`/users/token`)
	},
	async getLocations({ commit }) {
		this.dispatch('misc/setError', null)
		this.dispatch('misc/setLoading', true)

		try {
			const locations = await API.get(`/users/locations`)
			commit(types.SET_LOGIN_LOCATIONS, locations)
		} catch (error) {
			this.dispatch('misc/setError', error)
		} finally {
			this.dispatch('misc/setLoading', false)
		}
	},
	async setLocation({ commit }, { locationId, terminal, }) {
		this.dispatch('misc/setError', null)
		this.dispatch('misc/setLoading', true)

		try {
			const { token, uid, } = await API.send(`/users/location`, { locationId, terminal, })
			if (token) {
				this.dispatch('checkin/init')
				fb.setRefs(locationId, uid, terminal)

				commit(types.SET_LOGIN_LOCATION, locationId)
				commit(types.UNSET_LOGIN_LOCATIONS)
				await setPersistence(fb.auth, browserSessionPersistence)
				await signInWithCustomToken(fb.auth, token)
				router.push({ name: 'main', })
				commit(types.UNSET_LOGIN)
			}
		} catch (error) {
			await signOut(fb.auth)
			this.dispatch('misc/setError', error)
		} finally {
			this.dispatch('misc/setLoading', false)
		}
	},
	async recover({ commit }, email) {
		this.dispatch('misc/setError', null)
		this.dispatch('misc/setLoading', true)

		try {
			await API.send(`/users/recover`, { email }, false)
			this.dispatch('misc/setLoading', false)
		} catch (error) {
			this.dispatch('misc/setLoading', false)
			this.dispatch('misc/setError', error.message || error)
			throw new Error(error)
		}
	},
	async reset({ commit }, { token, password, passwordConfirm }) {
		this.dispatch('misc/setError', null)
		this.dispatch('misc/setLoading', true)
		try {
			await API.send(`/users/reset`, { password, passwordConfirm }, false, { Authorization: `Bearer ${token}` })
			this.dispatch('misc/setLoading', false)
		} catch (error) {
			this.dispatch('misc/setLoading', false)
			this.dispatch(`misc/setError`, error.message || error)
			throw new Error(error.message || error)
		}
	},
	async logout({ commit }) {
		commit(types.SET_LOGIN_STEP, LoginStep.Login)
		await signOut(fb.auth)
		this.dispatch('misc/setError', null)
		this.dispatch('misc/setLoading', false)

		commit(types.UNSET_ME,)
	},
	async create({ commit }, data) {
		this.dispatch('misc/setLoading', true)
		this.dispatch('misc/setError', null)

		try {
			const user = await API.send(`/users`, data)
			this.dispatch('misc/setLoading', false)
			return commit(types.SET_USER, user)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			this.dispatch('misc/setLoading', false)
			throw error
		}
	},
	async get({ commit }, uid) {
		this.dispatch('misc/setLoading', true)
		this.dispatch('misc/setError', null)

		try {
			const user = await API.get(`/users/${uid}`)
			this.dispatch('misc/setLoading', false)
			return commit(types.SET_USER, user)
		} catch (error) {
			this.dispatch('misc/setError', error)
			this.dispatch('misc/setLoading', false)
			throw error
		}
	},
	async list({ commit }, params) {
		this.dispatch('misc/setLoading', true)
		this.dispatch('misc/setError', null)

		try {
			let { docs, prevParams, nextParams, } = await API.get(`/users`, params)
			this.dispatch('misc/setLoading', false)
			this.dispatch('misc/setNext', nextParams)
			this.dispatch('misc/setPrev', prevParams)
			return commit(types.SET_USERS, docs)
		} catch (error) {
			this.dispatch('misc/setError', error)
			this.dispatch('misc/setLoading', false)
			throw error
		}
	},
	async update({ commit }, { uid, data }) {
		this.dispatch('misc/setLoading', true)
		this.dispatch('misc/setError', null)

		try {
			const user = await API.update(`/users/${uid}`, data)
			this.dispatch('misc/setLoading', false)
			return commit(types.SET_USER, user)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			this.dispatch('misc/setLoading', false)
			throw error
		}
	},
	unset({ commit }) {
		commit(types.UNSET_USER)
	},
	async resetMe({ commit }, { password, newPassword, newPasswordConfirm }) {
		try {
			await API.send(`/users/me/reset`, { password, newPassword, newPasswordConfirm }, true)
		} catch (error) {
			throw new Error(error.message || error)
		}
	},
	async updateMe({ commit }, { firstName, lastName, address, phone, settings }) {
		try {
			await API.update(`/users/me`, { firstName, lastName, address, phone, settings }, true)
		} catch (error) {
			throw new Error(error.message || error)
		}
	},
	async updateEmail({ commit }, { email, newEmail, password}) {
		let res
		try {
			res = await API.send(`/users/me/email`, { email, newEmail, password}, true)
		} catch (error) {
			throw new Error(error.message || error)
		}
		return res
	},
	async confirm({ commit, dispatch }, credentials) {
		let login
		try {
			login = await API.send(`/users/me/confirm`, credentials, false)
			if (login.token) {
				commit(types.LOGIN_USER, login)
			}
		} catch (error) {
			this.dispatch('misc/setError', error)
			throw error
		}

		return login
	},
	async getData({ commit }, uid) {
		try {
			const userData = await API.get(`/users/me`)
			commit(types.SET_ME, userData)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
		}
	},
}

const mutations = {
	[types.SET_ME](state, user) {
		state.me = user
	},
	[types.UNSET_ME](state, ) {
		state.me = {}
	},
	[types.SET_USER](state, user) {
		state.user = user
	},
	[types.SET_USERS](state, users) {
		state.users = users.map(u => ({...u, profileName: `${u.firstName} ${u.lastName}`}))
	},
	[types.UNSET_USER](state) {
		state.user = {}
	},
	[types.UNSET_USERS](state) {
		state.users = []
	},
	[types.SET_LOGIN_STEP](state, step) {
		state.loginStep = step
	},
	[types.SET_LOGIN_LOCATIONS](state, locations) {
		state.locations = locations
	},
	[types.SET_LOGIN_LOCATION](state, location) {
		state.location = location
	},
	[types.UNSET_LOGIN_LOCATIONS](state,) {
		state.locations = []
	},
	[types.UNSET_LOGIN_LOCATION](state,) {
		state.location = {}
	},
	[types.SET_LOGIN](state, login) {
		state.login = login
	},
	[types.UNSET_LOGIN](state, ) {
		state.login = {}
	}
}

const getters = {
	loginStep: state => {
		return state.loginStep
	},
	me: state => state.me,
	user: state => state.user,
	users: state => state.users,
	provider: state => state.me.provider,
	settings: state => state.me.settings,
	locations: state => state.locations,
	location: state => state.location,
	login: state => state.login,
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
}
