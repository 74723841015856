<template>
	<router-view id="app" />
</template>

<script>
export default {
	name: 'App',
	created() {
		document.addEventListener('SWUpdated', this.showRefresh)
	},
	mounted() {
		// TODO: Auth Check
	},
	methods: {
		showRefresh(evt) {
			this.$notice('New version of Quick Patient Dashboard available!', () => {
				evt.detail.postMessage({action: 'skipWaiting'})
			}, {
				actionButtonText: 'Click Here to Update',
				timeout: -1,
			})
		},
	},
	metaInfo: () => ({
		title: `Dashboard`,
		titleTemplate: `%s | Quick Patient`,
		meta: [
				{
					charset: 'utf-8',
				},
				{
					httpEquiv: 'X-UA-Compatible',
					content: 'IE-edge',
				},
				{
					name: 'description',
					content: '',
				},
				{
					name: 'viewport',
					content: 'width=device-width, initial-scale=1, maximum-scale=5',
				},
		],
		link: [
			{
				rel: 'stylesheet',
				href: '//fonts.googleapis.com/icon?family=Source+Sans+Pro:300,400,600,700,900|Lato:300,400,700,900|Material+Icons|Material+Icons+Outlined&display=swap',
			},
		],
	}),
}
</script>

<style lang="scss">
@import 'styles/partials';

</style>
