///
/// API Interface
///

import Axios from 'axios'
import { Auth } from '@/lib/auth'
import { getAuth } from 'firebase/auth'
import { getToken } from 'firebase/app-check'
import { fb } from '@/lib/fb'

const API_URL = process.env.API_URL || process.env.VUE_APP_API_URL

Axios.defaults.baseURL = API_URL
Axios.defaults.headers.common['Content-Type'] = 'application/json'
Axios.defaults.timeout = 300000

export const API = {

	async get(path, params, options = {}, withAuth = true, headers = {}) {
		if (withAuth) {
			const { token } = await fb.auth.currentUser.getIdTokenResult()
			if (!token) {
				throw new Error(JSON.stringify({ code: 400, message: `Request must be authenticated` }))
			}
			headers.Authorization = `Bearer ${token}`
		}

		try {
			const { token } = await getToken(fb.appCheck, true)
			headers['X-Firebase-AppCheck'] = token
		} catch (error) {
			console.warn(error)
		}

		return this._request({
			method: 'get',
			url: path,
			params,
			headers,
			...options,
		})
	},

	async send(path, data, withAuth = true, headers = {}) {
		if (withAuth) {
			const { token } = await fb.auth.currentUser.getIdTokenResult()
			if (!token) {
				throw new Error(JSON.stringify({ code: 400, message: `Request must be authenticated` }))
			}
			headers.Authorization = `Bearer ${token}`
		}

		try {
			const { token } = await getToken(fb.appCheck, true)
			headers['X-Firebase-AppCheck'] = token
		} catch (error) {
			console.warn(error)
		}

		return this._request({
			method: 'post',
			url: path,
			data,
			headers,
		})
	},

	async update(path, data, withAuth = true, headers = {}) {
		if (withAuth) {
			const { token } = await fb.auth.currentUser.getIdTokenResult()
			if (!token) {
				throw new Error(JSON.stringify({ code: 400, message: `Request must be authenticated` }))
			}
			headers.Authorization = `Bearer ${token}`
		}

		try {
			const { token } = await getToken(fb.appCheck, true)
			headers['X-Firebase-AppCheck'] = token
		} catch (error) {
			console.warn(error)
		}

		return this._request({
			method: 'put',
			url: path,
			data,
			headers,
		})
	},

	async remove(path, data, withAuth = true, headers = {}) {
		if (withAuth) {
			const { token } = await fb.auth.currentUser.getIdTokenResult()
			if (!token) {
				throw new Error(JSON.stringify({ code: 400, message: `Request must be authenticated` }))
			}
			headers.Authorization = `Bearer ${token}`
		}

		try {
			const { token } = await getToken(fb.appCheck, true)
			headers['X-Firebase-AppCheck'] = token
		} catch (error) {
			console.warn(error)
		}

		return this._request({
			method: 'delete',
			url: path,
			data,
			headers,
		})
	},

	_request(params) {
		const axios = Axios.create({
			// adapter: cacheAdapterEnhancer(Axios.defaults.adapter),
		})
		// axios.defaults.timeout = 360000
		return new Promise((resolve, reject) => {
			axios({ ...params })
				.then(r => r.data)
				.then(resolve)
				.catch(err => {

					if (err && err.response && err.response.data) {
						reject(err.response.data)
					} else {
						reject(err)
					}
				})
		})
	},
}
