///
/// Miscellaneous and shared store functions
///

import * as types from '../types'
import { router } from '@/lib/router'

/// STATE
const state = () => ({
	loading: 0,
	error: null,
	nextParams: undefined,
	prevParams: undefined,
})

const actions = {
	setLoading({ commit }, status) {
		if (status) {
			commit(types.SET_LOADING, 1)
		} else {
			commit(types.SET_LOADING, -1)
		}
	},
	setError({ commit }, error) {
		try {
			error = JSON.parse(error.message || error)
		} catch (error) { }


		if (error && error.code && error.code == 400) {
			return router.push({ name: 'login' })
		}
		commit(types.SET_ERROR, (error || {}).message || error)
	},
	setNext({ commit }, nextParams) {
		commit(types.SET_NEXT, nextParams)
	},
	setPrev({ commit }, prevParams) {
		commit(types.SET_PREV, prevParams)
	},
}

const mutations = {
	[types.SET_LOADING](state, status) {
		state.loading = Math.max(0, (state.loading + status))
	},
	[types.SET_ERROR](state, msg) {
		state.error = msg
	},
	[types.SET_NEXT](state, nextParams) {
		state.nextParams = nextParams
	},
	[types.SET_PREV](state, prevParams) {
		state.prevParams = prevParams
	},
}

const getters = {
	loading: state => !!state.loading,
	error: state => state.error,
	nextParams: state => state.nextParams,
	prevParams: state => state.prevParams,
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
}
