import * as types from '../types'

import { API } from '@/lib/api'
import { fb } from '@/lib/fb'
import { flatten, cleanObject } from '@/lib/utils'

const state = () => ({
	settings: [],
	setting: {},

})

const actions = {
	async list({ commit }, params) {
		this.dispatch('misc/setLoading', true)

		let settings
		try {
			settings = await API.get(`/settings`, params)
			commit(types.SET_SETTINGS, settings)
			this.dispatch('misc/setLoading', false)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			this.dispatch('misc/setLoading', false)
			throw new Error(error.message || error)
		}

		return settings
	},
	async get({ commit }, settingId) {
		let setting
		try {
			setting = await API.get(`/settings/${settingId}`)
			commit(types.SET_SETTING, setting)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			throw new Error(error.message || error)
		}

		return setting
	},
	async update({ commit }, { settingId, data }) {
		this.dispatch('misc/setLoading', true)

		let setting
		try {
			setting = await API.update(`/settings/${settingId}`, data)
			this.dispatch('misc/setLoading', false)
			commit(types.SET_SETTING, setting)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			this.dispatch('misc/setLoading', false)
			throw new Error(error.message || error)
		}

		return setting
	},
	async remove({ commit }, settingId) {
		try {
			await API.remove(`/settings/${settingId}`)
			commit(types.UNSET_SETTING)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			throw new Error(error.message || error)
		}
	},
	async create({ commit }, data) {
		this.dispatch('misc/setLoading', true)

		let setting
		try {
			setting = await API.send(`/settings`, data)
			this.dispatch('misc/setLoading', false)
			commit(types.SET_SETTING, setting)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			this.dispatch('misc/setLoading', false)
			throw new Error(error.message || error)
		}

		return setting
	},
	async unset({ commit }) {
		commit(types.UNSET_SETTING)
	},
}

const mutations = {
	[types.SET_SETTINGS](state, settings) {
		state.settings = settings
	},
	[types.UNSET_SETTINGS](state) {
		state.settings = []
	},
	[types.SET_SETTING](state, setting) {
		state.setting = setting
		let idx = state.settings.findIndex(c => c.settingId == setting.settingId)
		if (idx > -1) {
			state.settings[idx] = setting
		} else {
			state.settings.push(cleanObject(setting))
		}
	},
	[types.UNSET_SETTING](state) {
		state.setting = {}
	},
}

const getters = {
	settings: state => state.settings,
	setting: state => state.setting,
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
}
