import { API } from '@/lib/api'

const TOKENKEY = 'athenaToken'

const DEFAULTS = {}

const state = () => ({
	token: TOKENKEY,
})

const actions = {
	async getPatients({ commit, state, }, params) {
		const { firstName, lastName, email, gender, } = params

		let res = []
		try {
			const req = await API.get(
				`/providers/patients`,
				{
					lastName,
					firstName,
					email,
					gender,
				},
				{},
				true,
				{
					token: state.token,
					'X-Referrer': [window.location.origin, window.location.pathname].join(''),
				},
			)
			res = req.patients
		} catch (error) {
			throw new Error(error.message || error)
		}

		return res
	},

	async addPatient({ commit, state }, data) {
		const { checkin } = data
		if (!checkin) {
			throw new Error(`Missing required data.`)
		}

		const res = await API.send(
			`/providers/patient`,
			data,
			true,
			{
				token: state.token,
				'X-Referrer': [window.location.origin, window.location.pathname].join(''),
			},
		)

		return res
	},

	async updatePatient({ commit, state }, data) {
		const { checkin, patient } = data
		if (!checkin || !patient) {
			throw new Error(`Missing required data. Please make sure an existing patient is selected.`)
		}
		// console.log( { checkin, patient })

		const res = await API.update(
			`/providers/patient`,
			data,
			true,
			{
				token: state.token,
				'X-Referrer': [window.location.origin, window.location.pathname].join(''),
			},
		)

		return res
	},
}

const mutations = {}

const getters = {
	token: state => state.token,
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
}
