import * as types from '../types'

import { API } from '@/lib/api'
import { fb } from '@/lib/fb'
import { flatten, cleanObject, arrayBufferToString } from '@/lib/utils'
import { logEvent, } from 'firebase/analytics'
import { update as databaseUpdate, set as databaseSet, get as databaseGet, remove as databaseRemove, onChildAdded, onChildChanged, onChildRemoved, onValue, child, off } from 'firebase/database'
import { ref as storageRef, deleteObject, getDownloadURL } from 'firebase/storage'
import { TransferStatus } from '@/lib/enums'
import { Secure } from '@/lib/secure'
import Pako from 'pako'
import { differenceInYears, parse } from 'date-fns'
import { Axios } from 'axios'

const state = () => ({
	open: false,
	requestListener: null,
	receiptListener: null,
	pingListener: null,
	status: null,
})

const actions = {
	async setAccepting({ commit }, user) {
		if (!fb.refs.open) return

		const refData = {
			uid: user.uid,
			createdAt: (new Date()).getTime(),
		}

		let res
		try {
			res = await databaseUpdate(fb.refs.open, refData)
			commit(types.SET_OPEN)
		} catch (error) {
			console.error('setAccepting', error)
			commit(types.UNSET_OPEN)
		}

		return res
	},
	async unsetAccepting({ commit }, user) {
		if (!fb.refs.open) return

		const openSnap = await databaseGet(fb.refs.open)

		if (openSnap.exists() ) {
			const openVal = openSnap.val()
			if (openVal.uid == user.uid) {
				commit(types.UNSET_OPEN)
				return await databaseRemove(fb.refs.open)
			}
		}
	},
	async watchOpen({ commit, dispatch, rootGetters, state},) {
		const user = rootGetters['user/me']
		if (!user || !user.locationId || !user.uid || !fb.refs.open) return commit(types.UNSET_OPEN)

		const checkMatch = (snap) => {
			if (!snap.exists() || !snap.val()) {
				commit(types.UNSET_OPEN)
				return
			}


			if (snap.key !== 'uid' && snap.key !== user.locationId) {
				return
			}

			let uid
			if (snap.key === 'uid') {
				uid = snap.val()
			} else {
				uid = snap.val().uid
				// ({ uid }) = snap.val()
			}

			if (uid != user.uid) {
				commit(types.UNSET_OPEN)
				return
			}

			commit(types.SET_OPEN)
			return dispatch('watchTransfers')
		}
		const k = await databaseGet(fb.refs.open)
		if (k) {
			checkMatch(k)
		}

		const checkError = (error) => {
			console.error(error)
			commit(types.UNSET_OPEN)
		}

		onValue(fb.refs.open, checkMatch, checkError)
		onChildAdded(fb.refs.open, checkMatch, checkError)
		onChildChanged(fb.refs.open, checkMatch, checkError)
		onChildRemoved(fb.refs.open, checkMatch, checkError)
		onValue(child(fb.refs.open, 'ping'), (snap) => {
			if (snap.exists()) {
				databaseRemove(child(fb.refs.open, 'ping'))
			}
		})
	},
	unwatchOpen({ commit }) {
		off(fb.refs.open)
	},
	async watchTransfers({ commit, dispatch, rootGetters, state}) {
		if (state.requestListener) return
		const secure = Secure.getInstance()
		let keyPair
		let patient

		const transferError = (error) => {
			keyPair = undefined
			state.responseRef = undefined
			state.requestRef = undefined
			state.transferRef = undefined
			commit(types.SET_TRANSFER_STATUS, TransferStatus.Fail)
			if (error) {
				console.error(error)
			}
		}

		const requestRef = fb.refs.requests
		if (!requestRef) return
		state.requestListener = onChildAdded(requestRef, async (snap) => {
			if (!snap.exists()) return
			commit(types.SET_TRANSFER_STATUS, TransferStatus.Incoming)
			let { requestKey } = snap.val()
			if (!requestKey) {
				transferError(`Can not get request key`)
				return await databaseRemove(requestRef)
			}

			state.requestId = snap.key
			state.requestRef = snap.ref

			databaseRemove(snap.ref)

			try {
				keyPair = await secure.generateKeypair()
			} catch (error) {
				return transferError(error)
			}

			if (!keyPair) {
				return transferError(`Can not generate Key pair`)
			}

			if (!fb.refs.responses) {
				return transferError(`Missing ref: responses`)
			}
			state.responseRef = child(fb.refs.responses, state.requestId)

			try {
				databaseSet(state.responseRef, {
					responseKey: keyPair.publicKey,
				})
			} catch (error) {
				return transferError(error.message || error)
			}
			if (!fb.refs.transfers) {
				return transferError(`Missing ref: Transfers`)
			}
			if (!fb.refs.storage) {
				return transferError(`Missing ref: Storage`)
			}

			commit(types.SET_TRANSFER_STATUS, TransferStatus.Connecting)
			state.storageRef = storageRef(fb.refs.storage, state.requestId)
			state.transferRef = child(fb.refs.transfers, state.requestId)

			onChildAdded(state.transferRef, async (transferSnap) => {
				commit(types.SET_TRANSFER_STATUS, TransferStatus.Transferring)

				let data = transferSnap.val()

				databaseRemove(state.responseRef)
				databaseRemove(state.transferRef)

				try {
					const strData = atob(data)
					const charData = strData.split('').map(x => x.charCodeAt(0))
					const binData = new Uint8Array(charData)
					const dataArr = Pako.inflate(binData)
					data = arrayBufferToString(dataArr)
				} catch (error) {
					return transferError(error.message || error)
				}

				if (!data) {
					return transferError(`Can not read transfer data.`)
				}

				let requestPublicKey
				let requestSignKey
				let nonce
				let message
				let signed

				if (!requestKey) {
					return transferError(`Missing request key`)
				}

				try {
					({ requestPublicKey, requestSignKey } = await secure.parseKeys(requestKey))
				} catch (error) {
					return transferError(error.message || error)
				}

				if (!requestPublicKey || !requestSignKey) {
					return transferError(`Missing encryption keys.`)
				}

				try {
					({ nonce, message, signed } = await secure.parseEncryptedString(data))
				} catch (error) {
					requestPublicKey = undefined
					requestSignKey = undefined
					return transferError(error.message || error)
				}

				if (!keyPair) {
					return transferError(`No key pair generated.`)
				}

				try {
					const decrypted = await secure.decrypt(message, signed, nonce, requestPublicKey, keyPair.privateKey, requestSignKey)
					patient = JSON.parse(decrypted)
				} catch (error) {
					return transferError(error.message || error)
				}

				try {
					const imageFields = [
						'photo',
						'licensePhoto',
						'insurancePhotoFront',
						'insurancePhotoBack',
						'covidCardFront',
						'covidCardBack',
						'signature',
					]

					for (const imageField of imageFields) {
						if (patient[imageField]) {
							const imageRef = storageRef(state.storageRef, `${patient[imageField]}.txt`)

							try {
								const imageUrl = await getDownloadURL(imageRef)

								const imageRes = await new Promise((resolve, reject) => {
									const xhr = new XMLHttpRequest()
									// xhr.responseType = 'blob'
									xhr.open('GET',imageUrl)
									xhr.onload = (event) => {
										if (xhr.status >= 200 && xhr.status < 300) {
											resolve(xhr.response)
										}else {
											reject({
												status: xhr.status,
												statusText: xhr.statusText,
											})
										}
									}
									xhr.onerror = (error) => {
										reject({
											status: xhr.status,
											statusText: xhr.statusText,
										})
									}
									xhr.send()
								})
								// const imageRes = await Axios.get(imageUrl)
								let { nonce, message } = await secure.parseNonce(imageRes)
								const imgString = await secure.decryptImage(message, nonce, requestPublicKey, keyPair.privateKey)
								patient[imageField] = imgString
								// deleteObject(imageRef)
							} catch (error) {
								console.error(error)
								// deleteObject(imageRef)
							}
						}
					}
				} catch (error) {
					return transferError(error.message || error)
				}

				keyPair = undefined
				requestKey = undefined

				try {
					if (fb.refs.agreements) {
						const agreementsSnap = await databaseGet(fb.refs.agreements)
						const agreements = agreementsSnap.val() || []
						patient.agreements = agreements.map((a, i) => ({
							...patient.agreements[i],
							content: a.content,
						}))
					}
				} catch (error) {
					console.error(`Can not parse agreements`, error)
				}

				await databaseSet(child(fb.refs.receipts, state.requestId), {status: 'completed'})

				dispatch('checkin/addCheckin', patient, { root: true, })
				commit(types.SET_TRANSFER_STATUS, TransferStatus.Success)

				try {
					const user = rootGetters['user/me']
					let parsed = parse(patient.dob, 'MM/dd/yyyy', (new Date()))
					let age = Math.abs(differenceInYears(parsed, (new Date())))
					logEvent(fb.analytics, 'checkin', {
						client: user.client,
						clientId: user.location.clientId,
						location: user.location.name,
						locationId: user.location.locationId,
						gender: patient.gender,
						age,
					})
				} catch (error) {
					console.warn(`Can not log event: checkin`, error)
				}

				setTimeout(() => {
					// commit(types.SET_TRANSFER_STATUS, null)
				}, 10000)
			})

		})
	},
	async unwatchTransfers({ state }) {
		const requestsRef = fb.refs.requests
		if (!requestsRef) return
		state.requestsListener = undefined
		state.requestRef = undefined
		state.responseRef = undefined
		state.transferRef = undefined
		return requestsRef.off('child_added')
	},
	async cancelTransfer({ commit, state}) {
		if (state.requestRef) await state.requestRef.remove()
		if (state.responseRef) await state.responseRef.remove()
		if (state.transferRef) await state.transferRef.remove()
		commit(types.SET_TRANSFER_STATUS, TransferStatus.Cancelled)
		setTimeout(() => {
			commit(types.SET_TRANSFER_STATUS, null)
		}, 5000)
	}
}

const mutations = {
	[types.SET_OPEN](state) {
		state.open = true
	},
	[types.UNSET_OPEN](state) {
		state.open = false
	},
	[types.SET_TRANSFER_STATUS](state, status) {
		state.status = status
	},
	[types.SET_WATCH](state, user) {

	},
	[types.UNSET_WATCH] (state) {
		off(state.pingListener)
	}
}

const getters = {
	open: state => state.open,
	status: state => state.status,
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
}
