///
/// Vue Application
///

/// DEPENDENCIES
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'

/// PLUGINS
import VueMeta from 'vue-meta'
Vue.use(VueMeta)
import filters from './lib/filters'
Vue.use(filters)
import confirm from './lib/plugins/confirm'
Vue.use(confirm)
import notice from './lib/plugins/notice'
Vue.use(notice)

/// LIBRARY
import { router } from './lib/router'
import { store } from './lib/store'

Vue.config.productionTip = false
// Vue.config.ignoredElements = [/^ion-/]

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
