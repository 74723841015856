import { parseDomain, ParseResultType } from 'parse-domain'
import { v4 } from 'uuid'

export const formFields = require('@/assets/formFields.json')
export const covidFields = require('@/assets/covidFields.json')

export const getMainDomain = () => {
	let thisDomain = window.location.hostname

	try {
		const parseResult = parseDomain(window.location.hostname)
		if (parseResult.type == ParseResultType.Listed) {
			const { subDomains, domain, topLevelDomains } = parseResult
			thisDomain = [domain, topLevelDomains].join('.')
		}
	} catch (error) {
		console.warn('catch', error)
	}

	return thisDomain

}

export function forceDownload(data, filename = `download.csv`) {
	const url = window.URL.createObjectURL(new Blob([data]))
	const link = document.createElement('a')
	link.href = url
	link.setAttribute('download', filename)
	document.body.appendChild(link)
	link.click()
	link.parentNode.removeChild(link)
}

export const flatten = function (data, sep = '/') {
	var result = {};
	function recurse(cur, prop) {
		if (Object(cur) !== cur) {
			result[prop] = cur;
		} else if (Array.isArray(cur)) {
			for (var i = 0, l = cur.length; i < l; i++)
				recurse(cur[i], prop ? prop + sep + i : "" + i);
			if (l == 0)
				result[prop] = [];
		} else {
			var isEmpty = true;
			for (var p in cur) {
				isEmpty = false;
				recurse(cur[p], prop ? prop + sep + p : p);
			}
			if (isEmpty)
				result[prop] = {};
		}
	}
	recurse(data, "");
	return result;
}

export const cleanObject = function(obj) {
	return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null))
}

export function arrayBufferToString(buffer) {
	const bufView = new Uint16Array(buffer);
	const length = bufView.length;
	let result = '';
	let addition = Math.pow(2, 16) - 1;

	for (let i = 0; i < length; i += addition) {
		if (i + addition > length) {
			addition = length - i;
		}
		result += String.fromCharCode.apply(null, Array.prototype.slice.call(bufView.subarray(i, i + addition)) );
	}

	return result
}


export async function parseImage(data) {
	let i

	let imgData = await new Promise((resolve, reject) => {
		i = new Image()
		i.onload = (event) => resolve(i)
		if (typeof data == 'string') {
			i.src = `data:image/jpg;base64,${data}`
		} else {
			i.src = data.src
		}
	})
	// i = null

	return {
		src: imgData.src,
		height: imgData.height,
		width: imgData.width,
		data,
		obj: i,
	}
}

export async function mergeImage(one, two) {
	const c = document.createElement('canvas')
	const ctx = c.getContext('2d')
	if (!ctx) return
	const imageOne = await parseImage(one)
	const imageTwo = await parseImage(two)
	c.width = imageOne.width
	c.height = imageOne.height + imageTwo.height

	ctx.drawImage(imageOne.obj, 0, 0, imageOne.width, imageOne.height)
	ctx.drawImage(imageTwo.obj, 0, imageOne.height, imageTwo.width, imageTwo.height)

	const merged = c.toDataURL('image/jpeg')
	return {
		src: merged,
		height: imageOne.height + imageTwo.height,
		width: imageOne.width,
		data: merged.replace('data:image/jpeg;base64,', ''),
	}
}

export function formField(name, value, profile, fields, ) {
	if (!fields || !fields.length) {
		fields = formFields
	}

	const field = fields.find((f) => f.name == name)

	if (!field) {
		return {
			label: name,
			value
		}
	}

	let match = {
		type: field.type,
		label: field.label,
		icon: field.icon,
		name: field.name,
		value,
	}
	switch(field.type) {
		case 'dropdown':
			if (field.children[value]) {
				match.value = field.children[value]
			}
			break
		case 'signature':
			if (value) {
				const id = v4()
				match.value = `<a href="#${id}"><img src="data:image/jpg;base64,${value}" class="image"></a><a href="#_" class="lightbox" id="${id}"><img src="data:image/jpg;base64,${value}"></a>`
			}
			break
		case 'image':
			if (value) {
				let filename = field.label
				if (profile) {
					filename = `${profile['lastName']}, ${profile['firstName']} - ${field.label}.jpg`
				}
				const id = v4()
				match.value = `<a href="#${id}"><img src="data:image/jpg;base64,${value}" class="image"></a><a href="#_" class="lightbox" id="${id}"><img src="data:image/jpg;base64,${value}"></a><br><a href="data:image/jpg;base64,${value}" download="${filename}" class="mdc-button mdc-button--unelevated">
				<i class="material-icons mdc-button__icon" aria-hidden="true">get_app</i><span class="mdc-button__label">Download</span>
				</a>`
			}
			break
		case 'checkbox':
			match.value = match.value ? `YES` : `NO`
			// match.value = match.value ? `<i class="material-icons">check_box</i>` : `<i class="material-icons">check_box_outline_blank</i>`
			break
		case 'list':
			let listMatches = []
			for (const item in value) {
				const found = field.children.find((f) => f.name === item)
				if (found) {
					listMatches.push(found.label)
				}
			}

			match.value = listMatches.join('\n')
			break
		case 'modList':
			match.value = field.children
			break
	}


	return match
}
